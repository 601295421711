import React, { useEffect,useState } from "react";
import App_d_1 from '../Images/APP_1/App_d_1.png';
import rating_symbol from '../Images/rating_symbol.webp';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import ad_1 from '../Images/APP_1/ad_1.jpg';
import ad_2c from '../Images/APP_1/ad_2c.jpg';
import ad_3 from '../Images/APP_1/ad_3.jpg';
import { HiArrowSmRight } from 'react-icons/hi';
import more_app_1 from '../Images/download.png';
import more_app_2 from '../Images/APP_1/more_app_2.png';
import more_app_3 from '../Images/APP_1/more_app_3.png';
import more_app_4 from '../Images/APP_1/more_app_4.png';
import more_app_5 from '../Images/APP_1/more_app_5.png';
import more_app_6 from '../Images/APP_1/more_app_6.png';

import p1 from '../Images/p-1.gif';
import p2 from '../Images/p-2.gif';
import p3 from '../Images/p-3.gif';
import p4 from '../Images/p-4.gif';

import App_1_button from './App_1_button';
import { Link } from "react-router-dom";

export default function App1() {
    const [showModal, setShowModal] = React.useState(false);
    const [isInstallPromptVisible, setInstallPromptVisible] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        // Reload the page

        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
      const handleBeforeInstallPrompt = (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
        setInstallPromptVisible(true);
      };
  
      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  
      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
    }, []);
  
    const handleAddToHomeScreen = () => {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          setInstallPromptVisible(false);
          setDeferredPrompt(null);
        });
      }
    };

    return <>
        <div>

            <div class="min-w-screen min-h-screen flex items-center justify-center py-5">
                <div class=" text-gray-800 rounded-xl  relative overflow-x-auto w-[424px] overflow-x-hidden"  >
                    <div class="">
                        <div className="flex m-5 mt-7">
                            <img src={App_d_1} alt="Logo" className="h-[80px] w-[80px] rounded-2xl shadow-[0_3px_10px_rgb(0,0,0,0.2)]" />
                            <div className="ml-7">
                                <h1 className="font-roboto text-[23px] font-sans font-[500] leading-7">Virat Runner : Earning App</h1>
                                <p className="mt-2 text-[#01875f] font-[550] text-[19px]"> Earning App</p>
                            </div>
                        </div>
                        <div className="flex space-x-4 m-5">
                            <div className="">
                                <p className="text-center font-[600]">4.3  &#9733;</p>
                                <p className="text-[14px]">14.7K reviews</p>

                            </div>
                            <p className="mt-2 text-[20px] text-[#e6e6e6]">|</p>
                            <div>
                                <p className="text-center font-[600] ">100K+</p>
                                <p className="text-[14px]">Downloads</p>

                            </div>
                            <p className="mt-2 text-[20px] text-[#e6e6e6]">|</p>
                            <div>
                                <img src={rating_symbol} className="mx-auto mt-1" />
                                <p className="text-[14px] mt-1 flex">Rated for 3+ <HiOutlineInformationCircle className="mt-1 ml-1" /></p>
                            </div>

                        </div>
                        <center className="mt-10">
                            <button className="mx-auto " > <App_1_button /> </button>
                        </center>
                       
                        {isInstallPromptVisible && (
        <button onClick={handleAddToHomeScreen}>Add to Home Screen</button>
      )}
                    </div>
                    <div className="text-center justify-center flex my-2 space-x-8">
                        <div className="flex space-x-2">
                        <span class="VfPpkd-kBDsod y0nrBe" aria-hidden="true">
                            <svg class="f70z8e" width="24" height="24" viewBox="0 0 24 24" fill="#01875f">
                                <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path>
                            </svg>
                        </span>
                        <span className="text-[#01875f] font-[600] "> Share </span>
                        </div>
                        <div className="flex space-x-2">
                        <span class="VfPpkd-kBDsod y0nrBe" aria-hidden="true">
                        <svg fill="#9AA0A6" class="aThNrd" width="24" height="24" viewBox="0 0 24 24">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z"></path>
                                                                    </svg>
                        </span>
                        <span className="text-[#9AA0A6] font-[600]"> Add to wishlist </span>
                        </div>
                    </div>

                    <div class="container m-5 rounded-xl mt-[56px]">
                        <img src={ad_1} alt="image" className="h-[250px] w-full shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] rounded-xl" />
                        <img src={ad_2c} alt="image" className="h-[250px] w-full shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] rounded-xl" />
                        <img src={ad_3} alt="image" className="h-[250px] w-full shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] rounded-xl" />
                    </div>
                    <div className="flex m-5 text-xl font-[500] mt-4">
                        <h1>About this game</h1>
                        {/* <HiArrowSmRight size={30} className="ml-auto" onClick={() => setShowModal(true)} /> */}
                    </div>
                    <div class="bARER py-2 px-4" data-g-id="description">
                                                        Spin for rewards, Test your luck now and get coin in on the fun! 💰🎉
                                                        <br/><br/>
                                                        Spin to wheel is one of the best apps to earn rewards and rewarded spins. You can get free rewards in just a few steps.
                                                        <br/><br/>
                                                        Get some lucky spins in your spare time, Win Real and the best Rewards.
                                                        <br/><br/>
                                                        ✅Spin to wheel &amp; try Your Luck and Chance to Win Coin.<br/>
                                                        ✅Spin to wheel allows you To win real Coins and rewards.<br/>
                                                        ✅Daily spin will help you to earn more real coin.<br/>
                                                        ✅Earn daily pocket money by spin to win.
                                                        <br/><br/>
                                                        How do you rate our app?<br/>
                                                        We are always trying to improve what we do and your feedback is invaluable! Please Give us Good Rating.
                                                        <br/><br/>
                                                        Disclaimer:<br/>
                                                        We don't Collect Any user Personal Information In Our Application.<br/><br/>
                                                        We aren't Associated with any Company and Brand etc.
                                                    </div>
                                                    <br/>
                                                    <div class="TKjAsc pt-2 px-4">
                                                        <div>
                                                            <div class="lXlx5 font-[600]">Updated on</div>
                                                            <div class="xg1aie">Nov 18, 2023</div>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-center mt-2">
                                                            <p className="border-2 rounded-full border-[#dadce0] px-5 my-2 py-1">#7 Topfree casino apps</p>
                                                            <p className="border-2 rounded-full border-[#dadce0] px-5 ml-2 my-2 py-1">Casino</p>
                                                            </div>
                                                            <div className="flex justify-center">
                                                            <p className="border-2 rounded-full border-[#dadce0] px-5  py-1">Slot Machine</p>
                                                            <p className="border-2 rounded-full border-[#dadce0] px-5 ml-2 py-1">Cricket</p>
                                                            </div>

                                                    
                                                            <div >
  
      <div className="px-4 mt-4">
      <h2 className="text-2xl font-semibold mb-4">Data safety</h2>
      <p className="text-gray-700 mb-6">
        Safety starts with understanding how developers collect and share your data. Data privacy and security practices may vary based on your use, region, and age. The developer provided this information and may update it over time.
      </p>

      {/* Data safety info section */}
      <div className="">
        <div className="mb-4">
          <div className="flex items-center mb-2 space-x-2">
            <img src={p1} className="w-8"/>
            <p className="text-gray-800 font-[10px]">
              This app may share these data types with third parties
            </p>
          </div>
          <p className="ml-9 font-[600] text-gray-500">Device or other IDs</p>
        </div>

        <div className="mb-4">
          <div className="flex items-center mb-2">
          <img src={p2} className="w-6" />

            <p className="text-gray-800 font-[10px] ml-2">
              This app may collect these data types
            </p>
          </div>
          <p className="ml-9 font-[600] text-gray-500">Personal info and Photos and videos</p>
        </div>

        <div className="mb-4">
          <div className="flex items-center mb-2 space-x-2">
          <img src={p3}  className="w-6" />

            <p className="text-gray-800 font-medium">
              Data isn’t encrypted
            </p>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex items-center mb-2 space-x-2">
          <img src={p4} className="w-6" />

            <p className="text-gray-800 font-medium">
              You can request that data be deleted
            </p>
          </div>
        </div>
 
        <button className="text-[#01875f] font-[600] mt-4 ">See details</button>
      </div>
      <div className="mt-3">
      {/* Google Play Header */}
      <div className="flex items-center mb-6">
      
        <h2 className="text-xl font-semibold ">Ratings and reviews</h2>
      </div>

      {/* Device Buttons */}
      <div className="flex mb-4 space-x-2">
        <button className="px-4 py-2 border rounded-full bg-gray-100 text-sm">Phone</button>
        <button className="px-4 py-2 border rounded-full text-sm">Tablet</button>
      </div>

      {/* Rating Section */}
      <div className="flex items-center mb-4">
        <span className="text-4xl font-semibold">4.9</span>
        <div className="ml-4 flex items-center">
          {/* Stars */}
          <div className="flex text-yellow-400">
            <span className="material-icons">star</span>
            <span className="material-icons">star</span>
            <span className="material-icons">star</span>
            <span className="material-icons">star</span>
            <span className="material-icons">star_half</span>
          </div>
        </div>
        <p className="text-gray-500 text-sm ml-4">2.9M reviews</p>
      </div>

      {/* Rating Bars */}
      <div className="space-y-1 mb-6">
        {[5, 4, 3, 2, 1].map((rating) => (
          <div key={rating} className="flex items-center">
            <span className="text-gray-600 text-sm w-5">{rating}</span>
            <div className="flex-1 bg-gray-200 h-2 mx-2 rounded">
              <div
                className={`bg-green-500 h-2 rounded`}
                style={{ width: `${rating === 5 ? '90%' : rating === 4 ? '60%' : '30%'}` }}
              ></div>
            </div>
          </div>
        ))}
      </div>

      {/* User Review */}
      <div className="border-t pt-4">
        <div className="flex items-center mb-2">
          <img
            src="https://i.pravatar.cc/40"
            alt="Reviewer"
            className="w-10 h-10 rounded-full"
          />
          <div className="ml-3">
            <h3 className="text-gray-800 text-sm font-semibold">Jaydon Culhane</h3>
            <p className="text-gray-500 text-xs">September 22, 2023</p>
          </div>
        </div>

        {/* Review Text */}
        <div className="text-sm text-gray-700">
          <div className="flex text-yellow-400 mb-2">
            {/* Rating Stars */}
            
                                                                                
            <span className="material-icons">star</span>
            <span className="material-icons">star</span>
            <span className="material-icons">star</span>
            <span className="material-icons">star</span>
            <span className="material-icons">star_border</span>
          </div>
          <p>
            This application is very easy to use. The application is smooth and does not crash, 
            the picture is clear, the operation is comfortable, and the gameplay is rich and diverse. 
            Friends can try it. I highly recommend it!
          </p>
        </div>
      </div>
    </div>
      {/* Ratings and reviews section */}
      
    </div>

      
    </div>

                    {/* <div className="m-5">
                        <p className="text-[15px]">Are you ready to turn your free time into a rewarding adventure? Look no further than GoRupee,
                            the ultimate Easy Earning App designed to transform the way you make money from the comfort of
                            your own device. Whether you're looking to earn a little extra cash or seeking a sustainable income stream,
                            GoRupee offers... </p>
                        <p className="mt-4"> Updated on </p>
                        <p>Jul 5, 2023</p>
                    </div> */}






                    <div className="m-5">
                        <h1 className="text-xl font-[500]"> More By Earning App </h1>
                    </div>
                    <div >
                        <div className="grid grid-cols-2 m-4 mb-14">
                            <Link to='/app_2'> <div className="flex" >
                                <img src={App_d_1} className="w-[55px] h-[55px] rounded-xl shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]" />
                                <div className="text-[15px] ml-2">
                                    <p> Rupee Maker </p>
                                    <p> Earning App </p>
                                    <p> 4.5  &#9733;</p>
                                </div>
                            </div> </Link>
                            {/* <Link to='/app_3'> <div className="flex">
                                <img src={more_app_2} className="w-[55px] h-[55px] rounded-xl shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]" />
                                <div className="text-[15px] ml-2">
                                    <p>EasyRupee : Earn</p>
                                    <p> Finance Bites LLC </p>
                                    <p> 4.2  &#9733;</p>
                                </div>
                            </div></Link>
                            <Link to="/app_6"><div className="flex mt-5">
                                <img src={more_app_3} className="w-[55px] h-[55px] rounded-xl shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]" />
                                <div className="text-[15px] ml-2">
                                    <p>Visit For Earn : M  </p>
                                    <p> Finance Bites LLC </p>
                                    <p> 4.3  &#9733;</p>
                                </div>
                            </div></Link>
                            <Link to="/app_4"><div className="flex mt-5">
                                <img src={more_app_4} className="w-[55px] h-[55px] rounded-xl shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]" />
                                <div className="text-[15px] ml-2">
                                    <p> spin For Earn - E </p>
                                    <p> Finance Bites LLC </p>
                                    <p> 4.5  &#9733;</p>
                                </div>
                            </div></Link> */}
                            {/* <div className="flex mt-5">
                                <img src={more_app_5} className="w-[55px] h-[55px] rounded-xl shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]" />
                                <div className="text-[15px] ml-2">
                                    <p>Auto Call Record</p>
                                    <p> Finance Bites LLC </p>
                                    <p> 4.3  &#9733;</p>
                                </div>
                            </div> */}
                            {/* <Link to="/app_7"> <div className="flex mt-5">
                                <img src={more_app_6} className="w-[55px] h-[55px] rounded-xl shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]" />
                                <div className="text-[15px] ml-2">
                                    <p> Task Cash : Cash</p>
                                    <p> Finance Bites LLC </p>
                                    <p> 4.4  &#9733;</p>
                                </div>
                            </div></Link> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>;
}