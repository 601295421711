import React, { useState, useEffect } from 'react';
import apk from './virat-runner.apk';

import trueimage from '../Images/check.png';
import info1 from '../Images/info1.png';
import info2 from '../Images/info2.png';
import info3 from '../Images/Group 9.png';

import img1 from '../Images/1 count.png';
import img2 from '../Images/2 count.png';
import img3 from '../Images/3 count.png';
import deleteicon from "../Images/delete.png"
import axios from 'axios';

// Modal Component
const Modal = ({ onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative bg-gray-50 p-5 rounded-lg text-center w-[320px]">
                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="absolute top-2 right-0  flex items-center justify-center"
                >
                    <img src={deleteicon} alt="Close" className="w-8 h-8" /> 
                </button>



                {/* Grid of steps */}
                <div className="flex justify-center mt-2">
                    <div className="w-full max-w-md">
                        <h2 className="text-xl font-bold text-green-600 mb-4">Installation Process</h2>
                        <hr className="border-t-2 border-green-600 mb-4" />

                        {/* Step 1 */}
                        <div className="bg-[#0FB8863D] w-full rounded-xl py-1 mb-4">

                            <div className="grid grid-cols-4 py-2 items-start rounded-2xl px-2">
                                <div className="col-span-1 text-left">
                                    <span className="flex justify-center items-center text-green-600">
                                        <img src={img1} className="w-4 h-4" />
                                    </span>
                                </div>
                                <div className="col-span-3">
                                    <p className="text-gray-700 text-sm text-left font-semibold mt-[-8px]">Download and open the installation file</p>
                                </div>
                            </div>
                            <img src={info1} className="mx-auto" />
                        </div>

                        {/* Step 2 */}
                        <div className="bg-[#0FB8863D] w-full rounded-xl py-1 mb-4">

                            <div className="grid grid-cols-4 py-2 items-start rounded-2xl px-2">
                                <div className="col-span-1 text-left">
                                    <span className="flex justify-center items-center text-green-600">
                                        <img src={img2} className="w-4 h-4" />
                                    </span>
                                </div>
                                <div className="col-span-3">
                                    <p className="text-gray-700 text-sm text-left font-semibold mt-[-8px]">Grant permission to install apps</p>
                                </div>
                            </div>
                            <img src={info2} className="mx-auto" />
                        </div>

                        {/* Step 3 */}
                        <div className="bg-[#0FB8863D] w-full rounded-xl py-1 mb-4">

                            <div className="grid grid-cols-4 py-2 items-start rounded-2xl px-2">
                                <div className="col-span-1 text-left">
                                    <span className="flex justify-center items-center text-green-600">
                                        <img src={img3} className="w-4 h-4" />
                                    </span>
                                </div>
                                <div className="col-span-3">
                                    <p className="text-gray-700 text-sm text-left font-semibold mt-[-8px]">Complete the installation and open the app</p>
                                </div>
                            </div>
                            <img src={info3} className="mx-auto" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// const Demo = () => {
//     const [progress, setProgress] = useState(0);
//     const [isModalOpen, setIsModalOpen] = useState(false);

//     const handleDownload = () => {
//         const xhr = new XMLHttpRequest();
//         xhr.open('GET', apk, true);
//         xhr.responseType = 'blob';

//         xhr.onprogress = (event) => {
//             if (event.lengthComputable) {
//                 const percentage = (event.loaded / event.total) * 100;
//                 setProgress(Math.round(percentage));
//             }
//         };

//         xhr.onload = () => {
//             if (xhr.status === 200) {
//                 const url = window.URL.createObjectURL(new Blob([xhr.response]));
//                 const link = document.createElement('a');
//                 link.href = url;
//                 link.setAttribute('download', 'Virat Runner - Earning App.apk');
//                 document.body.appendChild(link);
//                 link.click();
//                 link.remove();
//                 setProgress(0);
//             }
//         };

//         xhr.send();
//     };

//     const startDownload = () => {
//         setIsModalOpen(true); // Open modal

//     };

//     const onClosemodal = () => {
//         setIsModalOpen(false);
//         handleDownload(); // Start download after closing the modal
//     };

//     return (
//         <main>
//             <div className="container">
//                 <a
//                     href="#"
//                     onClick={(e) => {
//                         e.preventDefault();
//                         startDownload();
//                     }}
//                     style={{
//                         backgroundColor: "#01875f",
//                         padding: "5px",
//                         display: "block",
//                         width: "345px",
//                         boxSizing: "border-box",
//                         textDecoration: "none",
//                         textAlign: "center",
//                         color: "white",
//                         borderRadius: "5px",
//                     }}
//                 >
//                     Install {progress > 0 && <span>{progress}% </span>}
//                 </a>
//                 {isModalOpen && (
//                     <Modal
//                         onClose={onClosemodal}
//                     />
//                 )}
//             </div>
//         </main>
//     );
// };

// export default Demo;






// const Demo = () => {
//     const [progress, setProgress] = useState(0);
//     const [installCount, setInstallCount] = useState(null);
//     const [downloadCount, setDownloadCount] = useState(null);

//     // Function to fetch counts
//     const fetchCounts = async () => {
//         try {
//             const response = await axios.get('https://tronixpayment.axispay.cloud/api/count');
//             setDownloadCount(response.data.downloadCount);
//             setInstallCount(response.data.installCount);
//         } catch (error) {
//             console.error("Error fetching counts:", error);
//         }
//     };

//     // Polling to update download and install count every 10 seconds
//     useEffect(() => {
//         fetchCounts(); // Initial fetch
//         const interval = setInterval(fetchCounts, 10000); // Fetch every 10 seconds

//         return () => clearInterval(interval); // Cleanup on unmount
//     }, []);

//     const handleInstallAndTrack = async () => {
//         try {
//             await axios.post('https://tronixpayment.axispay.cloud/api/track-download');
//             await axios.post('https://tronixpayment.axispay.cloud/api/track-install');

//             const xhr = new XMLHttpRequest();
//             xhr.open('GET', apk, true);
//             xhr.responseType = 'blob';

//             xhr.onprogress = (event) => {
//                 if (event.lengthComputable) {
//                     const percentage = (event.loaded / event.total) * 100;
//                     setProgress(Math.round(percentage));
//                 }
//             };

//             xhr.onload = () => {
//                 if (xhr.status === 200) {
//                     const url = window.URL.createObjectURL(new Blob([xhr.response]));
//                     const link = document.createElement('a');
//                     link.href = url;
//                     link.setAttribute('download', 'Virat Runner - Earning App.apk');
//                     document.body.appendChild(link);
//                     link.click();
//                     link.remove();
//                     setProgress(0);
//                 }
//             };
//             fetchCounts();
//             xhr.send();
//         } catch (error) {
//             console.error("Error during installation tracking and fetching counts:", error);
//         }
//     };

//     return (
//         <main>
//             <button
//                 onClick={handleInstallAndTrack}
//                 style={{
//                     backgroundColor: "#01875f",
//                     padding: "5px",
//                     display: "block",
//                     width: "345px",
//                     boxSizing: "border-box",
//                     textDecoration: "none",
//                     textAlign: "center",
//                     color: "white",
//                     borderRadius: "5px",
//                 }}
//             >
//                 Install {progress > 0 && <span>{progress}%</span>}
//             </button>
//             <div>
//                 <p>Total downloads: {downloadCount !== null ? downloadCount : 'Loading...'}</p>
//                 <p>Total installs: {installCount !== null ? installCount : 'Loading...'}</p>
//             </div>
//         </main>
//     );
// };

// export default Demo;


const Demo = () => {
    const [progress, setProgress] = useState(0);
    const [installCount, setInstallCount] = useState(null);
    const [downloadCount, setDownloadCount] = useState(null);
  

    // Deep link and fallback URL
    const deepLink = "myapp://"; // Replace with your app’s URL scheme
    const fallbackUrl = "https://play.google.com/store/apps/details?id=com.icr.cricspin"; // Replace with your Play Store link

    // Function to fetch counts
    const fetchCounts = async () => {
        try {
            const response = await axios.get('https://tronixpayment.axispay.cloud/api/count');
            setDownloadCount(response.data.downloadCount);
            setInstallCount(response.data.installCount);
        } catch (error) {
            console.error("Error fetching counts:", error);
        }
    };
 

    // Polling to update download and install count every 10 seconds
    useEffect(() => {
        fetchCounts(); // Initial fetch
        const interval = setInterval(fetchCounts, 10000); // Fetch every 10 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    const handleInstallAndTrack = async () => {
        try {
            // Attempt to open the app using the deep link
            window.location.href = deepLink;

            // Set a timeout to check if the app opened
            const appOpenTimeout = setTimeout(() => {
                // If the app did not open, redirect to the fallback URL (Play Store)
                window.location.href = fallbackUrl;
            }, 2000); // Wait for 2 seconds

            // Track download and installation
            await axios.post('https://tronixpayment.axispay.cloud/api/track-download');
            await axios.post('https://tronixpayment.axispay.cloud/api/track-install');

            const xhr = new XMLHttpRequest();
            xhr.open('GET', apk, true);
            xhr.responseType = 'blob';

            xhr.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentage = (event.loaded / event.total) * 100;
                    setProgress(Math.round(percentage));
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([xhr.response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Virat Runner - Earning App.apk');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setProgress(0);
                }
                // Clear the timeout since the download was successful
                clearTimeout(appOpenTimeout);
            };

            xhr.send();
        } catch (error) {
            console.error("Error during installation tracking and fetching counts:", error);
        }
    };

    return (
        <main>
            <button
                onClick={handleInstallAndTrack}
                style={{
                    backgroundColor: "#01875f",
                    padding: "5px",
                    display: "block",
                    width: "345px",
                    boxSizing: "border-box",
                    textDecoration: "none",
                    textAlign: "center",
                    color: "white",
                    borderRadius: "5px",
                }}
            >
                Install {progress > 0 && <span>{progress}%</span>}
            </button>
            
           
        </main>
    );
};

export default Demo;
